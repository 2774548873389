import request from '@/utils/request'
import fileDownload from '@/utils/fileDownload'

// 获取能源模块卡片统计数据--月度
export function getEnergyData(siteId) {
    return request({
      url: `/bivale/energy/data/monthly/${siteId}`,
      method: 'get',
    });
}

// 获取能源模块卡片统计数据---日度
export function getEnergyDailyData(siteId) {
    return request({
      url: `/bivale/energy/data/daily/${siteId}`,
      method: 'get',
    });
}


// 获取能源模块卡片报表
export function getEnergyReport(params) {
    return request({
      url: `/bivale/energy/energy/reportall`,
      method: 'get',
      params
    });
}

// 能源模块--设备页--获取类型
// export function passCopy(params) {
//     return request({
//         url: "bivale/accessRole/entrance/pass/copy",
//         method: 'post',
//         params,
//     });
// }

// 能源模块--设备页--获取类型   
export function equipmentTypeList(params) {
    return request({
        url: 'bivale/energy/device/type/list',
        method: 'get',
        params
    });
}

  // 能源模块--设备页--获取卡片列表
export function getCardList(params) {
    return request({
        url: "bivale/energy/device/list",
        method: 'post',
        data:params,
    });
}

// 能源模块--设备页--获取拓扑图计量点列表
export function getTopologyList(params) {
return request({
    url: "bivale/energy/device/topology",
    method: 'post',
    data:params,
});
}

// 能源模块--获取能源设备信息
export function getEnergyEquipmentDetail(id) {
    return request({
        url: "bivale/energy/device/info/"+id,
        method: 'get',
    });
}

// 能源模块--获取能源设备对应信号点信息
export function getEnergySignalDetail(pgrId,kind,signalId) {
    return request({
        url: "bivale/energy/device/"+pgrId+"/"+kind+"/point/"+signalId,
        method: 'get',
    });
}

// 能源模块--更新能源设备对应信号点信息
export function updateEnergyEquipmentDetail(param) {
    console.log(" 能源模块--更新能源设备对应信号点信息");
    console.log(param);
    return request({
        url: "bivale/energy/device/update",
        method: 'post',
        data:param
    });
}

// 能源模块--获取原单位指标信息
export function getBasicSetupData(param) {
    console.log(" 能源模块--设置--基本设置--获取原单位指标信息");
    console.log(param);
    return request({
        url: "bivale/energy/setting/unit/norm/info",
        method: 'post',
        data:param
    });
}

// 能源模块--删除原单位指标信息
export function deleteBasicSetupData(param) {
    console.log(" 能源模块--设置--基本设置--删除原单位指标信息");
    console.log(param);
    return request({
        url: "bivale/energy/setting/unit/norm/delete",
        method: 'post',
        data:param
    });
}

// 能源模块--设置原单位指标信息
export function saveBasicSetupData(param) {
    console.log(" 能源模块--设置--基本设置--设置原单位指标信息");
    console.log(param);
    return request({
        url: "bivale/energy/setting/unit/norm/save",
        method: 'post',
        data:param
    });
}

// 能源模块--获取原单位指标信息
export function getIntensityIndexData(param) {
    console.log(" 能源模块--设置--基本设置--获取原单位指标信息");
    console.log(param);
    return request({
        url: "bivale/energy/setting/unit/norm/value/info",
        method: 'post',
        data:param
    });
}
// 能源模块--设置原单位指标信息
export function saveIntensityIndexData(param) {
    console.log(" 能源模块--设置--基本设置--设置原单位指标信息");
    console.log(param);
    return request({
        url: "bivale/energy/setting/unit/norm/value/save",
        method: 'post',
        data:param
    });
}

// 能源模块--获取削减目标信息
export function getReductionTargetData(param) {
    console.log(" 能源模块--设置--基本设置--获取削减目标信息");
    console.log(param);
    return request({
        url: "bivale/energy/setting/reduction/target/info",
        method: 'post',
        data:param
    });
}
// 能源模块--设置削减目标信息
export function saveReductionTargetData(param) {
    console.log(" 能源模块--设置--基本设置--设置削减目标信息");
    console.log(param);
    return request({
        url: "bivale/energy/setting/reduction/target/save",
        method: 'post',
        data:param
    });
}


// 能源模块--获取能源使用类型信息
export function getEnergyUseKindSetupData(param) {
    console.log(" 能源模块--设置--基本设置--获取能源使用类型信息");
    console.log(param);
    return request({
        url: "bivale/energy/setting/kind/used/info",
        method: 'post',
        data:param
    });
}
// 能源模块--设置能源使用类型信息
export function saveEnergyUseKindSetupData(param) {
    console.log(" 能源模块--设置--基本设置--设置能源使用类型信息");
    console.log(param);
    return request({
        url: "bivale/energy/setting/kind/used/save",
        method: 'post',
        data:param
    });
}

// 能源模块--查询能源设备数据输出（预览）信息
export function getEnergyTrendLogList(param) {
    console.log(" 能源模块--查询能源设备数据输出（预览）信息");
    console.log(param);
    return request({
        url: "bivale/energy/history/trend/list",
        method: 'post',
        data:param
    });
}

// 能源模块--下载设备趋势记录
export function downloadEnergyTrendLogList(param) {
    console.log(" 能源模块--下载设备趋势记录");
    console.log(param);
    return request({
        url: "bivale/energy/history/trend/download",
        method: 'post',
        data:param
    });
}

// 能源模块--下载设备趋势记录文件
export function downloadEnergyTrendLogFile(param) {
    console.log(" 能源模块--下载设备趋势记录文件");
    console.log(param);
    return request({
        url: "bivale/energy/history/trend/files/download",
        method: 'post',
        data:param
    });
}

// 能源模块--获取能源设备趋势记录文件
export function getEnergyTrendLogFileList(param) {
    console.log(" 能源模块--获取能源设备趋势记录文件");
    console.log(param);
    return request({
        url: "bivale/energy/history/trend/files",
        method: 'post',
        data:param
    });
}

// 能源模块--计量对象组管理
export function getEneMeasureGroup(param) {
    console.log(" 能源模块--计量对象组管理",param);
    return request({
        url: "bivale/energy/setting/measure/group",
        method: 'post',
        data:param
    });
}

// 能源模块--计量对象组详细管理
export function getEneMeasureGroupDetail(param) {
    console.log("能源模块--计量对象组详细管理",param);
    return request({
        url: "bivale/energy/setting/measure/group/object",
        method: 'post',
        data:param
    });
}


// 能源模块--计量点--能源类型设置
export function getEneKindSetup(param) {
    console.log("能源模块--计量点--能源类型设置",param);
    return request({
        url: "bivale/energy/setting/point/enekind",
        method: 'post',
        data:param
    });
}

// 能源模块--计量点--本司/租户设置
export function getEneOwnTenDivSetup(param) {
    console.log("能源模块--计量点--本司/租户设置",param);
    return request({
        url: "bivale/energy/setting/point/owntendiv",
        method: 'post',
        data:param
    });
}

// 能源模块--计量点管理-计量对象区分设置 
export function getEneMeasureObjectSetup(param) {
    console.log("能源模块--计量点--计量对象区分",param);
    return request({
        url: "bivale/energy/setting/point/statisticalmeasure",
        method: 'post',
        data:param
    });
}
//能源模块--计量点--获取能源-虚拟点列表
export function getVirtialPointList(param) {
    console.log("能源模块--计量点--获取能源-虚拟点列表",param);
    return request({
        url: "bivale/energy/setting/point/virtual/list",
        method: 'post',
        data:param
    });
}
//能源模块--计量点--获取能源-虚拟点详细信息
export function getVirtialPointData(param) {
    console.log("能源模块--计量点--获取能源-虚拟点详细信息",param);
    return request({
        url: "bivale/energy/setting/point/virtual/info",
        method: 'post',
        data:param
    });
}
//能源模块--计量点--新增/更新能源-虚拟点信息
export function saveVirtialPointData(param) {
    console.log("能源模块--计量点--新增/更新能源-虚拟点信息",param);
    return request({
        url: "bivale/energy/setting/point/virtual/save",
        method: 'post',
        data:param
    });
}
//能源模块--计量点--删除能源-虚拟点
export function deleteVirtialPointData(param) {
    console.log("能源模块--计量点--删除能源-虚拟点",param);
    return request({
        url: "bivale/energy/setting/point/virtual/delete",
        method: 'post',
        data:param
    });
}

//能源模块--计量点--获取能源信号点（虚拟点，运算点）GR列表
export function getEnePointGrList(param) {
    console.log("能源模块--计量点--获取能源信号点（虚拟点，运算点）GR列表",param);
    return request({
        url: "bivale/energy/setting/point/gr/list",
        method: 'post',
        data:param
    });
}

//能源模块--计量点--获取能源信号点（虚拟点，运算点）GR列表
export function getEnePointGrDBList(params) {
    console.log("能源模块--计量点--获取能源信号点（虚拟点，运算点）GR列表",params);
    return request({
        url: "bivale/energy/setting/point/grDB/list",
        method: 'post',
        params
    });
}

//能源模块--计量点--获取能源信号点（虚拟点，运算点）关系列表
export function getEnePointRelationDBList(params) {
    console.log("能源模块--计量点--获取能源信号点（虚拟点，运算点）关系列表",params);
    return request({
        url: "bivale/energy/setting/point/relationDB/list",
        method: 'post',
        params
    });
}


//能源模块--计量点--能源信号点（虚拟点，运算点）GR
export function deleteEnePointGr(param) {
    console.log("能源模块--计量点--删除能源信号点（虚拟点，运算点）GR",param);
    return request({
        url: "bivale/energy/setting/point/gr/delete",
        method: 'post',
        data:param
    });
}

//能源模块--计量点--新增/更新能源信号点（虚拟点，运算点）GR
export function saveEnePointGr(param) {
    console.log("能源模块--计量点--新增/更新能源信号点（虚拟点，运算点）GR",param);
    return request({
        url: "bivale/energy/setting/point/gr/save",
        method: 'post',
        data:param
    });
}

//能源模块--计量点--获取能源运算点列表
export function getCalcPointList(param) {
    console.log("能源模块--计量点--获取能源运算点列表",param);
    return request({
        url: "bivale/energy/setting/point/calculate/list",
        method: 'post',
        data:param
    });
} 
//能源模块--设置--获取能源运算点信息
export function getCalcPointData(param) {
    console.log("能源模块--设置--获取能源运算点信息",param);
    return request({
        url: "bivale/energy/setting/point/calculate/info",
        method: 'post',
        data:param
    });
}  
//能源模块--设置--新增修改运算点
export function saveCalcPointData(param) {
    console.log("能源模块--设置--新增修改运算点",param);
    return request({
        url: "bivale/energy/setting/point/calculate/save",
        method: 'post',
        data:param
    });
} 
//能源模块--设置--删除运算点
export function deleteCalcPointData(param) {
    console.log("能源模块--设置--删除运算点",param);
    return request({
        url: "bivale/energy/setting/point/calculate/delete",
        method: 'post',
        data:param
    });
}

//能源模块--设置--日报/月报/年报列表处理
export function getEneReportGrList(param) {
    console.log("能源模块--设置--日报/月报/年报列表处理",param);
    return request({
        url: "bivale/energy/setting/report/gr",
        method: 'post',
        data:param
    });
}

//能源模块--设置--日报/月报/年报详情处理
export function getEneReportGrDetail(param) {
    console.log("能源模块--设置--日报/月报/年报详情处理",param);
    return request({
        url: "bivale/energy/setting/report/gr/info",
        method: 'post',
        data:param
    });
}
//能源模块--设置--跨站点（日报/月报/年报）信号点选择信号点列表
export function getBuildReportGrPointList(param) {
    console.log("能源模块--设置--日报/月报/年报信号点选择信号点列表",param);
    return request({
        url: "bivale/energy/setting/build/point/list",
        method: 'post',
        data:param
    });
}

//能源模块--设置--日报/月报/年报信号点选择信号点列表
export function getEneReportGrPointList(param) {
    console.log("能源模块--设置--日报/月报/年报信号点选择信号点列表",param);
    return request({
        url: "bivale/energy/setting/report/point/list",
        method: 'post',
        data:param
    });
}
///energy/setting/report/point/save
//能源模块--设置--日报/月报/年报组-保存报表组计量点
export function saveEneReportGrPointList(param) {
    console.log("能源模块--设置--日报/月报/年报组-保存报表组计量点",param);
    return request({
        url: "bivale/energy/setting/report/point/save",
        method: 'post',
        data:param
    });
}

//能源模块--实际数据-统计数据
export function getEnergyStatisticsData(param) {
    console.log("能源模块--实际数据-统计数据",param);
    return request({
        url: "bivale/energy/data/statistics",
        method: 'post',
        data:param
    });
} 
//能源模块--实际数据-下载统计数据
export function downloadEnergyStatisticsData(param) {
    console.log("能源模块--实际数据-下载统计数据",param);
    return request({
        url: "bivale/energy/data/statistics/download",
        method: 'post',
        data:param
    });
} 


//能源模块--实际数据-计量对象组比对图
export function getEnergyComparisonData(param) {
    console.log("能源模块--实际数据-计量对象组比对图数据",param);
    return request({
        url: "bivale/energy/data/group/statistics",
        method: 'post',
        data:param
    });
} 

//能源模块--实际数据-实际使用量
export function getEnergyActualUsageData(param) {
    console.log("能源模块--实际数据-实际使用量",param);
    return request({
        url: "bivale/energy/data/actual/usage",
        method: 'post',
        data:param
    });
} 


//能源模块--实际数据--日报、月报、年报数据
export function getEnergyReportData (param) {
    console.log("能源模块--实际数据--日报、月报、年报数据",param);
    return request({
        url: "bivale/energy/data/report/detail",
        method: 'post',
        data:param
    });
} 


//能源模块--实际数据--保存日报、月报、年报数据
export function saveEnergyReportData (param) {
    console.log("能源模块--实际数据--保存日报、月报、年报数据",param);
    return request({
        url: "bivale/energy/data/report/save",
        method: 'post',
        data:param
    });
} 


//能源模块--实际数据--获取计量表读取数据（能源综合报表）
export function getEnergyMeterReadingData(param) {
    console.log("能源模块--实际数据--获取计量表读取数据（能源综合报表）",param);
    return request({
        url: "bivale/energy/data/meter/reading",
        method: 'post',
        data:param
    });
} 


export function downloadEnergyMeterReadingData(param){
    console.log("能源模块--实际数据--获取计量表读取数据（能源综合报表）",param);
    return request({
        url: "bivale/energy/data/meter/download",
        method: 'post',
        data:param
    });
} 


export function downloadEnergyCSVData(param){
    console.log("能源模块--下载CSV数据",param);
    return request({
        url: 'bivale/energy/data/meter/tempalte/download',
        method: 'post',
        data:param
    });
} 


export function getEnergyDataOutputData(param){
    console.log("能源模块--数据输出模块",param);
    return request({
        url: 'bivale/energy/data/output',
        method: 'post',
        data:param
    });
} 

export function getEnergyAnalysisData(param){
    console.log("能源模块--分析-节能管理",param);
    return request({
        url: 'bivale/energy/data/analysis/saving',
        method: 'post',
        data:param
    });
} 




export function getEnergyAnalysisYearData(param){
    console.log("能源模块--分析-统计年度比较",param);
    return request({
        url: 'bivale/energy/data/analysis/year',
        method: 'post',
        data:param
    });
} 




export function getEnergyAnalysisBuildData(param){
    console.log("能源模块--分析-建物比较",param);
    return request({
        url: 'bivale/energy/data/analysis/building',
        method: 'post',
        data:param
    });
} 

export function getEnergyActualInputData(param){
    console.log("能源模块--实际输入 -- 实际输入数据",param);
    return request({
        url: 'bivale/energy/data/actual/input',
        method: 'post',
        data:param
    });
} 

export function saveEnergyActualInputData(param){
    console.log("能源模块--实际输入 -- 保存实际输入数据",param);
    return request({
        url: 'bivale/energy/data/actual/input/save',
        method: 'post',
        data:param
    });
} 


export function getEnergyActualUnInputData(param){
    console.log("能源模块--实际输入 -- 实际输入数据",param);
    return request({
        url: 'bivale/energy/data/uninput/confirm',
        method: 'post',
        data:param
    });
} 

export function saveEnergyActualUnInputData(param){
    console.log("能源模块--实际输入 -- 保存实际输入数据",param);
    return request({
        url: 'bivale/energy/data/uninput/confirm/save',
        method: 'post',
        data:param
    });
} 


// 能源模块--警报 -- 警报记录数据
export function getEnergyNotificationList(params) {
    console.log("能源模块--警报 -- 警报记录数据",params);
    return request({
        url: 'bivale/energy/history/notification/list',
        method: 'post',
        data:params
    });
}

// 警报 -- 警报记录数据(分布)
export function getNotificationPageList(params) {
    console.log("警报 -- 警报记录数据(分页)",params);
    return request({
        url: 'bivale/alarm/history/page/list',
        method: 'post',
        data:params
    });
}

// 能源模块--记录 -- 日志记录数据
export function getEnergyHourlyList(params) {
    console.log("能源模块--记录 -- 日志记录数据",params);
    return request({
        url: 'bivale/energy/history/logging/list',
        method: 'post',
        data:params
    });
}

// 能源模块--记录 -- 趋势记录数据
export function getEnergyTrendList(params) {
    console.log("能源模块--记录 -- 趋势记录数据",params);
    return request({
        url: 'bivale/energy/history/trend/list',
        method: 'post',
        data:params
    });
}


export function getEquivalentDataList(params) {
    console.log("能源模块--能源换算值设置",params);
    return request({
        url: 'bivale/energy/setting/equivalent/data',
        method: 'post',
        data:params
    });
}
export function saveEquivalentData(params) {
    console.log("能源模块-设置/复制能源换算值设置",params);
    return request({
        url: 'bivale/energy/setting/equivalent/data/save',
        method: 'post',
        data:params
    });
}


// 通用设备模块--获取设备信息
export function getFACEquipmentDetail(id) {
    return request({
        url: "bivale/energy/device/fac/info/"+id,
        method: 'get',
    });
}

//通用设备模块-设置设备信号当前值
export function updatePointSignalPresentValue(params) {
    console.log("通用设备模块-设置设备信号当前值",params);
    return request({
        url: 'bivale/energy/device/point/update/presentValue',
        method: 'post',
        data:params
    });
}


//通用设备模块-获取设备试验信息
export function getEnergyDeviceAnnouncementInfo(params) {
    console.log("通用设备模块-获取设备试验信息",params);
    return request({
        url: 'bivale/energy/device/'+params.equipmentId+'/announcement/'+params.id,
        method: 'get',
    });
}


//通用设备模块-发布设备试验信息
export function saveAnnouncementInfo(params) {
    console.log("通用设备模块-发布设备试验信息",params);
    return request({
        url: 'bivale/energy/device/announcement/save',
        method: 'post',
        data:params
    });
}


//能源模块-获取设备树状信息 added by li_yj on 2022-4-22
export function getEnergyDataTreeCalculate(params) {
    console.log("能源模块-获取设备树状信息",params);
    return request({
        url: '/bivale/energy/data/treeCalculate',
        method: 'post',
        params
    });
}

// 电能管理--获取设备树状信息 added by li_yj on 2022-4-22
export function getEnergyTreeInfo(params) {
    return request({
        url: 'bivale/energy/data/tree',
        method: 'post',
        params
    });
}

// 电能管理--获取设备拓扑图信息 added by li_yj on 2022-4-22
export function getTopologyInfo(params) {
    return request({
        url: 'bivale/energy/device/topology',
        method: 'post',
        data: params
    });
}

// 电能管理--获取计量点设备信息列表 added by li_yj on 2022-5-9
export function getEnergyPointGrPathList(params) {
    return request({
        url: 'bivale/energy/setting/point/grPath/list',
        method: 'post',
        params
    });
}

// 电能管理--获取计量点设备信息列表 added by li_yj on 2022-5-6
export function getEnergyPointGrDBList(params) {

    return request({
        url: 'bivale/energy/setting/point/grDB/list',
        method: 'post',
        params
    });
}

//能源模块--能耗管理-站点能耗对比
export function getEnergySitesStatisticsData(param) {
    console.log("能源模块--能耗管理-站点能耗对比",param);
    return request({
        url: "bivale/energy/data/sites/statistics",
        method: 'post',
        data:param
    });
} 


//能源模块--获取信号点设置时间比较趋势数据
export function getEnergySitesTrendData(param) {
    console.log("能源模块--获取信号点设置时间比较趋势数据",param);
    return request({
        url: "bivale/energy/data/site/trend/normal",
        method: 'post',
        data:param
    });
} 

//能源模块--获取时间设置数据比较趋势数据
export function getEnergySitesTimeSetupTrendData(param) {
    console.log("能源模块--获取时间设置数据比较趋势数据",param);
    return request({
        url: "bivale/energy/data/site/trend/timeSetup",
        method: 'post',
        data:param
    });
} 
// 电能管理 -- 设置 -- 拓扑图设置 -- 获取能源信号点关系信息树
export function getTopologySetTree(params) {
    return request({
        url: 'bivale/energy/setting/point/relationDB/tree',
        method: 'post',
        params
    });
}

// 电能管理 -- 设置 -- 拓扑图设置 -- 获取计量点列表数据
export function getGRList(params) {
    return request({
        url: 'bivale/energy/setting/point/grDB/list',
        method: 'post',
        params
    });
}

// 电能管理 -- 设置 -- 拓扑图设置 -- 获取运算点列表数据
export function getOperationList(params) {
    return request({
        url: 'bivale/energy/setting/point/calculate/list',
        method: 'post',
        data:params
    });
}

// 电能管理 -- 设置 -- 拓扑图设置 -- 获取虚拟点列表数据
export function getVirtualList(params) {
    return request({
        url: 'bivale/energy/setting/point/virtual/list',
        method: 'post',
        data:params
    });
}

// 电能管理 -- 设置 -- 拓扑图设置 -- 新增/更新能源信号侧边树形节点（单节点）
export function amendTreeData(params) {
    return request({
        url: 'bivale/energy/setting/point/relation/save',       
        method: 'post',
        data:params
    });
}

// 电能管理 -- 设置 -- 拓扑图设置 -- 新增/更新能源信号侧边树形节点（多节点）
export function amendTreeDatas(params) {
    return request({
        url: 'bivale/energy/setting/point/relation/list/save',       
        method: 'post',
        data:params
    });
}

// 电能管理 -- 设置 -- 拓扑图设置 -- 删除能源信号侧边树形节点
export function deleteTreeNode(params) {
    return request({
        url: 'bivale/energy/setting/point/relation/delete',       
        method: 'post',
        data:params
    });
}

// 电能管理 -- 设置 -- 拓扑图设置 -- 获取能源设备关系拓扑信息
export function getTreeTopology(params) {
    return request({
        url: 'bivale/energy/setting/point/relationDB/treeTopology',       
        method: 'post',
        params
    });
}

// 电能管理--新增/更新能源设备联动控制规则 added by li_yj on 2022-6-7
export function saveLinkageControlRule(params) {
    return request({
        url: 'bivale/energy/linkage/linkageControlRule/save',
        method: 'post',
        data: params
    });
}

// 电能管理--新增能源设备联动控制规则 added by li_yj on 2022-6-27
export function addLinkageControlRule(params) {
    return request({
        url: 'bivale/energy/linkage/linkageControlRule/add',
        method: 'post',
        data: params
    });
}

// 电能管理--删除能源设备联动控制规则信息 added by li_yj on 2022-6-7
export function deleteLinkageControlRule(params) {
    return request({
        url: 'bivale/energy/linkage/linkageControlRule/delete',
        method: 'post',
        data: params
    });
}

// 电能管理--删除能源设备联动控制规则信息 added by li_yj on 2022-7-14
export function deleteLinkageControlRuleList(params) {
    return request({
        url: 'bivale/energy/linkage/linkageControlRuleList/delete',
        method: 'post',
        data: params
    });
}

// 电能管理--获取能源设备联动控制规则信息 added by li_yj on 2022-6-7
export function getLinkageControlRuleById(params) {
    return request({
        url: 'bivale/energy/linkage/linkageControlRule',
        method: 'post',
        params
    });
}

// 电能管理--获取能源设备联动控制规则信息列表 added by li_yj on 2022-6-7
export function getLinkageControlRuleByExample(params) {
    return request({
        url: 'bivale/energy/linkage/linkageControlRuleList',
        method: 'post',
        data: params
    });
}
// 电能管理--获取能源设备联动控制规则信息组 added by li_yj on 2022-6-9
export function getLinkageControlRuleCompositBySiteId(params) {
    return request({
        url: 'bivale/energy/linkage/linkageControlRuleComposit',
        method: 'post',
        params
    });
}

// 电能管理--更新能源设备联动控制规则 added by li_yj on 2022-6-15
export function updateLinkageControlRuleComposit(params) {
    return request({
        url: 'bivale/energy/linkage/update/linkageControlRuleComposit',
        method: 'post',
        data: params
    });
}

// 电能管理 -- 设置 -- 能源换算系数 -- 站点换算系数列表
export function getSiteEquivList(params) {
    return request({
        url: 'bivale/energy/setting/equiv/site/list',       
        method: 'post',
        data:params
    });
}


// 电能管理 -- 设置 -- 能源换算系数 -- 区域换算系数列表
export function getAreaEquivList(params) {
    return request({
        url: 'bivale/energy/setting/equiv/area/list',       
        method: 'post',
        data:params
    });
}



// 电能管理 -- 设置 -- 能源换算系数 -- 站点换算系数列表
export function getSiteEquivInfo(id) {
    return request({
        url: 'bivale/energy/setting/equiv/site/'+id,       
        method: 'get'
    });
}


// 电能管理 -- 设置 -- 能源换算系数 -- 区域换算系数信息
export function getAreaEquivInfo(id) {
    return request({
        url: 'bivale/energy/setting/equiv/area/'+id,       
        method: 'get'
    });
}


// 电能管理 -- 设置 -- 能源换算系数 -- 站点换算系数保存
export function saveSiteEquivInfo(params) {
    return request({
        url: 'bivale/energy/setting/equiv/site/save',       
        method: 'post',
        data:params
    });
}


// 电能管理 -- 设置 -- 能源换算系数 -- 区域换算系数保存
export function saveAreaEquivInfo(params) {
    return request({
        url: 'bivale/energy/setting/equiv/area/save',       
        method: 'post',
        data:params
    });
}

// 电能管理 -- 设置 -- 能源换算系数 -- 站点换算系数保存
export function deleteSiteEquivInfo(params) {
    return request({
        url: 'bivale/energy/setting/equiv/site/delete',       
        method: 'post',
        data:params
    });
}


// 电能管理 -- 设置 -- 能源换算系数 -- 区域换算系数保存 
export function deleteAreaEquivInfo(params) {
    return request({
        url: 'bivale/energy/setting/equiv/area/delete',       
        method: 'post',
        data:params
    });
}

// 能源管理 -- 设置 -- 能耗管理 -- 虚拟设备列表
export function getVirtialEquipmentList(params) {
    return request({
        url: 'bivale/energy/virtual/equipment/list',       
        method: 'post',
        data:params
    });
}
// 能源管理 -- 设置 -- 能耗管理 -- 虚拟设备信息
export function getVirtialEquipment(id) {
    return request({
        url: 'bivale/energy/virtual/equipment/'+id,       
        method: 'get'
    });
}
// 能源管理 -- 设置 -- 能耗管理 -- 虚拟设备新增/修改
export function saveVirtialEquipment(params) {
    return request({
        url: 'bivale/energy/virtual/equipment/save',       
        method: 'post',
        data:params
    });
} 
// 能源管理 -- 设置 -- 能耗管理 -- 虚拟设备删除
export function deleteVirtialEquipment(params) {
    return request({
        url: 'bivale/energy/virtual/equipment/delete',       
        method: 'post',
        data:params
    });
}


// 能源管理 -- 设置 -- 能耗管理 -- 生产线列表
export function getProductionLineList(params) {
    return request({
        url: 'bivale/energy/production/line/list',       
        method: 'post',
        data:params
    });
}

// 能源管理 -- 设置 -- 能耗管理 -- 生产线保存
export function saveProductionLine(params) {
    return request({
        url: 'bivale/energy/production/line/save',       
        method: 'post',
        data:params
    });
}

// 能源管理 -- 设置 -- 能耗管理 -- 生产线删除
export function deleteProductionLine(id) {
    return request({
        url: 'bivale/energy/production/line/delete/'+id,       
        method: 'post'
    });
}


// 能源管理 -- 设置 -- 能耗管理 -- 生产能耗列表
export function getProductionConsumptionList(params) {
    return request({
        url: 'bivale/energy/production/consumption/page',       
        method: 'post',
        data:params
    });
}

// 能源管理 -- 设置 -- 能耗管理 -- 生产能耗详情
export function getProductionConsumption(id) {
    return request({
        url: 'bivale/energy/production/consumption/'+id,       
        method: 'post'
    });
}

// 能源管理 -- 设置 -- 能耗管理 -- 生产能耗保存
export function saveProductionConsumption(params) {
    return request({
        url: 'bivale/energy/production/consumption/save',       
        method: 'post',
        data:params
    });
}

// 能源管理 -- 设置 -- 能耗管理 -- 生产能耗删除
export function deleteProductionConsumption(params) {
    return request({
        url: 'bivale/energy/production/consumption/delete',       
        method: 'post',
        data:params
    });
}

// 能源管理 -- 设置 -- 能耗管理 -- CSV上传生产能耗
export function uploadProductionConsumption(params) {
    return request({
        url: 'bivale/energy/production/consumption/upload',
        method: 'post',
        processData: false,
        data:params
    });
}

// 能耗管理 ---- 能源流向查询
export function energyFlowData(params) {
    return request({
        url: 'bivale/energy/data/flow/chart',
        method: 'post',
        data:params
    });
}


// 能源管理 -- 设置 -- 能耗管理 -- 生产能耗报表
export function getProductionConsumptionReport(params) {
    return request({
        url: 'bivale/energy/production/consumption/report',       
        method: 'post',
        data:params
    });
}

// 电能管理--获取能源设备计量点、运算点或虚拟点信息 added by li_yj on 2022-7-1
export function getEnergyDataPointInfoList(params) {
    return request({
        url: 'bivale/energy/setting/point/infoList',
        method: 'post',
        params
    });
}

// 电能管理--获取能源峰谷设置信息组 added by li_yj on 2022-7-6
export function getPeakValleyCompositBySiteId(params) {
    return request({
        url: 'bivale/energy/peakValley/peakValleyComposit',
        method: 'post',
        params
    });
}

// 电能管理--新增/更新能源峰谷设置信息 added by li_yj on 2022-7-6
export function savePeakValley(params) {
    return request({
        url: 'bivale/energy/peakValley/save',
        method: 'post',
        data: params
    });
}

// 电能管理--新增/更新能源峰谷设置信息列表 added by li_yj on 2022-7-8
export function savePeakValleyList(params) {
    return request({
        url: 'bivale/energy/peakValley/save/list',
        method: 'post',
        data: params
    });
}

// 电能管理--新增能源峰谷设置信息 added by li_yj on 2022-7-6
export function addPeakValley(params) {
    return request({
        url: 'bivale/energy/peakValley/add',
        method: 'post',
        data: params
    });
}

// 电能管理--删除能源峰谷设置信息 added by li_yj on 2022-7-6
export function deletePeakValley(params) {
    return request({
        url: 'bivale/energy/peakValley/delete',
        method: 'post',
        data: params
    });
}

// 电能管理--获取能源峰谷设置信息 added by li_yj on 2022-7-6
export function getPeakValleyById(params) {
    return request({
        url: 'bivale/energy/peakValley/info',
        method: 'post',
        params
    });
}

// 电能管理--获取能源峰谷设置信息列表 added by li_yj on 2022-7-6
export function getPeakValleyByExample(params) {
    return request({
        url: 'bivale/energy/peakValley/list',
        method: 'post',
        data: params
    });
}

// 电能管理--更新能源峰谷设置信息 added by li_yj on 2022-7-6
export function updatePeakValleyComposit(params) {
    return request({
        url: 'bivale/energy/peakValley/update/peakValleyComposit',
        method: 'post',
        data: params
    });
}


// 能源管理 -- 设置 -- 日报/月报/年报 -- CSV数据上传
export function uploadEnergyData(params) {
    return request({
        url: 'bivale/energy/data/upload',
        method: 'post',
        processData: false,
        data:params
    });
}
// 能源管理 -- 实际数据 -- 统计数据 -- 日报/月报/年报修改
export function fixUpdateEnergyData(params) {
    return request({
        url: 'bivale/energy/data/fixed/update',       
        method: 'post',
        data:params
    });
}

// 电能管理--取指定时间（日）峰谷能耗 added by li_yj on 2022-7-17
export function getHourlyDataOfDay(params) {
    return request({
        url: 'bivale/energy/peakValley/data/hourlyOfDay',
        method: 'post',
        data: params
    });
}

// 电能管理--取指定时间（月）峰谷能耗 added by li_yj on 2022-7-17
export function getDailyDataOfMonth(params) {
    return request({
        url: 'bivale/energy/peakValley/data/dailyOfMonth',
        method: 'post',
        data: params
    });
}

// 电能管理--取指定时间（年）峰谷能耗 added by li_yj on 2022-7-22
export function getMonthlyDataOfYear(params) {
    return request({
        url: 'bivale/energy/peakValley/data/monthlyOfYear',
        method: 'post',
        data: params
    });
}

// 电能管理--取指定时间（日）峰谷每小时功率数据 added by li_yj on 2022-7-26
export function getHourlyDataOfDayPower(params) {
    return request({
        url: 'bivale/energy/peakValley/data/power/hourlyOfDay',
        method: 'post',
        data: params
    });
}

// 电能管理--取计量点 added by li_yj on 2022-7-26
export function getMeasurementPointList(params) {
    return request({
        url: '/bivale/energy/data/pointList',
        method: 'post',
        data: params
    });
}

// 电能管理--取指定时间（月）峰谷每日功率数据 added by li_yj on 2022-7-27
export function getDailyDataOfMonthPower(params) {
    return request({
        url: 'bivale/energy/peakValley/data/power/dailyOfMonth',
        method: 'post',
        data: params
    });
}

// 电能管理--取指定时间（年）峰谷每月功率数据 added by li_yj on 2022-7-28
export function getMonthDataOfYearPower(params) {
    return request({
        url: 'bivale/energy/peakValley/data/power/monthOfYear',
        method: 'post',
        data: params
    });
}

// 电能管理 --  能效KPI设置初始化页面数据
export function KPISetDataInitialization(params) {
    return request({
        url: 'bivale/energy/reduction/aim/list',
        method: 'post',
        data:params
    });
}
  
// 电能管理 --  能效KPI年度规则设置
export function KPIAnnualRuleSetting(params) {
  return request({
      url: 'bivale/energy/kpi/aim/fiscal/year/save',
      method: 'post',
      data:params
  });
}
  
// 电能管理 --  能效KPI年度规则删除
export function KPIAnnualRuleDelete(params) {
    return request({
        url: `bivale/energy/kpi/aim/fiscal/year/delete/${params}`,
        method: 'post',
    });
}
  
// 电能管理 --  能效KPI目标值设置
export function KPISetting(params) {
    return request({
        url: 'bivale/energy/reduction/aim/value/save',
        method: 'post',
        data: params
    });
}

// 电能管理 --  能效KPI目标值设置 
export function KPIAutoUpdate(params) {
    return request({
        url: 'bivale/energy/reduction/aim/task/update',
        method: 'post',
        data: params
    });
}

// 电能管理 --  能效KPI显示--页面初始化数据
export function KPIInitialize(params) {
    return request({
        url: 'bivale/energy/kpi/list',
        method: 'post',
        data: params
    });
    }

// 电能管理--获取能源设备联动控制规则信息列表 added by li_yj on 2022-8-2
export function getLinkageControlRuleByObjectIdAndType(params) {
    return request({
        url: 'bivale/energy/linkage/linkageControlRuleList/objectIdAndType',
        method: 'post',
        data: params
    });
}

 // 能源管理 --  能效KPI显示--KPI警报设置相关API
export function getKPIAlarmList(params){
    return request({
        url: 'bivale/energy/reduction/aim/alarm/list',
        method: 'post',
        data: params
    });
}

export function updateKPIAlarm(params){
    return request({
        url: 'bivale/energy/reduction/aim/alarm/update',
        method: 'post',
        data: params
    });
}

export function batchUpdateKPIAlarm(params){
    return request({
        url: 'bivale/energy/reduction/aim/alarm/batch/update',
        method: 'post',
        data: params
    });
}

// 电能管理 --  能效KPI显示--CSV下载
export function KPICSVdownload(params) {
    return request({
        url: 'bivale/energy/kpi/download',
        method: 'post',
        data: params
    });
}

// 电能管理 -- 能耗管理 -- 能耗报表设置 -- 模版列表数据获取
export function getTemplateList(params) {
    return request({
        url: 'bivale/template/energy/list',       
        method: 'get',
        params
    });
}

// 电能管理 -- 能耗管理 -- 能耗报表设置 -- 模版列表模版添加
export function getTemplateAdd(params,data) {
    return request({
        url: 'bivale/template/energy/add',       
        method: 'post',
        params,
        data:data,
    });
}

// 电能管理 -- 能耗管理 -- 能耗报表设置 -- 模版列表模版名称修改 
export function getTemplateAmend(data) {
    return request({
        url: 'bivale/template/energy/update',       
        method: 'post',
        data:data,
    });
}

// 电能管理 -- 能耗管理 -- 能耗报表设置 -- 模版列表模版删除
export function getTemplateDelete(params) {
    return request({
        url: 'bivale/template/energy/delete',       
        method: 'post',
        params,
    });
}

// 电能管理 -- 能耗管理 -- 能耗报表设置 -- 获取模版数据
export function getTemplateDatas(params) {
    return request({
        url: 'bivale/template/energy/getTemplateInformation',       
        method: 'post',
        params,
    });
}

// 电能管理 -- 能耗管理 -- 能耗报表设置 -- 获取模版数据设置
export function getTemplateDataSet(params,data) {
    return request({
        url: 'bivale/template/energy/setUp',       
        method: 'post',
        params,
        data:data
    });
}

// 电能管理 -- 能耗管理 -- 自动报表设置 -- 侧边栏数据获取
export function getAutomaticReportGrList(params) {
    return request({
        url: 'bivale/automaticReport/automaticReportGrList',       
        method: 'get',
        params
    });
}

// 电能管理 -- 能耗管理 -- 自动报表设置 -- 报表列表增加
export function automaticReportAdd(params) {
    return request({
        url: 'bivale/automaticReport/add',       
        method: 'post',
        data:params
    });
}

// 电能管理 -- 能耗管理 -- 自动报表设置 -- 报表列表名称更新
export function automaticReportNameUpdate(params) {
    return request({
        url: 'bivale/automaticReport/update',       
        method: 'post',
        data:params
    });
}

// 电能管理 -- 能耗管理 -- 自动报表设置 -- 报表列表删除
export function automaticReportdelete(params) {
    return request({
        url: 'bivale/automaticReport/delete',       
        method: 'post',
        data:params
    });
}

// 电能管理 -- 能耗管理 -- 自动报表设置 -- 获取报表详情数据
export function getAutomaticReportGrDetail(params) {
    return request({
        url: 'bivale/automaticReport/detail',       
        method: 'get',
        params
    });
}

// 电能管理 -- 能耗管理 -- 自动报表设置 -- 获取报表文件列表
export function getAutomaticReportFiles(params) {
    return request({
        url: 'bivale/automaticReport/files',       
        method: 'post',
        params
    });
}

// 电能管理 -- 能耗管理 -- 自动报表设置 -- 保存报表详情数据
export function setAutomaticReport(params,data) {
    return request({
        url: 'bivale/automaticReport/saving',       
        method: 'post',
        params,
        data:data
    });
}

// 电能管理 -- 能耗管理 -- 自动报表设置 -- 报表下载
export function automaticReportDownload(url) {
    return fileDownload({
        url: url,       
        method: 'post',
        responseType: "blob",
    });
}

// 电能管理 -- 能耗管理 -- 清洁能源 -- 清洁能源数据获取
export function getCleanEnergy(data) {
    return request({
        url: 'bivale/energy/data/clean/report/data',       
        method: 'post',
        data:data
    });
}

// 电能管理 -- 能耗管理 -- 清洁能源 -- 清洁能源数据CSV下载
export function getCleanEnergyCSV(data) {
    return request({
        url: 'bivale/energy/data/clean/report/download',       
        method: 'post',
        data:data
    });
}

// 电能管理 -- 能耗管理 -- 清洁能源 -- 清洁能源流向数据获取
export function getCleanEnergyFlows(data) {
    return request({
        url: 'bivale/energy/data/clean/flow/data',       
        method: 'post',
        data:data
    });
}

// 电能管理 -- 能耗管理 -- 清洁能源设置 -- 清洁能设置分组列表
export function getCleanEnergySetGroupList(data) {
    return request({
        url: 'bivale/energy/setting/clean/point/data',       
        method: 'post',
        data:data
    });
}

// 电能管理 -- 能耗管理 -- 清洁能源设置 -- 清洁能源设置添加/编辑分组
export function getCleanEnergySetGroupEdit(data) {
    return request({
        url: 'bivale/energy/setting/clean/point/save',       
        method: 'post',
        data:data
    });
}

// 电能管理 -- 能耗管理 -- 清洁能源设置 -- 清洁能源设置添加/编辑分组
export function getCleanEnergySetGroupDelete(id) {
    return request({
        url: `bivale/energy/setting/clean/point/delete/${id}`,       
        method: 'post'
    });
}

// 能源管理 -- 获取能源客户/建筑/站点看板数据
export function getEnergyKanBanData(params){
    return request({
        url: 'bivale/energy/kanban/data/info',       
        method: 'post',
        data:params
    });
}

// 电能管理--更新能源峰谷设置信息列表 added by li_yj on 2022-9-1
export function updatePeakValleyList(params) {
    return request({
        url: 'bivale/energy/peakValley/update/list',
        method: 'post',
        data: params
    });
}

// 能源管理 -- 获取能源站点信息 
export function getEnergySiteList(){
    return request({
        url: 'bivale/custom/client/build/tree/energy',       
        method: 'post',
        data:null
    });
}
// 能源管理 -- 获取能源客户/建筑/站点看板设置信息
export function getEnergyKanBanSetting(params){
    return request({
        url: 'bivale/energy/kanban/setting/info',       
        method: 'post',
        data:params
    });
}

// 能源管理 -- 设置能源客户/建筑/站点看板设置信息
export function saveEnergyKanBanSetting(params){
    return request({
        url: 'bivale/energy/kanban/setting/save',       
        method: 'post',
        data:params
    });
}

//能源模块--实际数据--获取计量表读取数据（能源综合报表）
export function getEnergyMeterReadingTableData(param) {
    return request({
        url: "bivale/energy/data/report/tempalte/detail",
        method: 'post',
        data:param
    });
} 


// 能源管理 -- 设置能源集团客户公司信息
export function saveEnergyKanBanBranch(params){
    return request({
        url: 'bivale/energy/kanban/branch/save',       
        method: 'post',
        data:params
    });
}

// 能源管理 -- 删除能源集团客户公司信息
export function deleteEnergyKanBanBranch(id){
    return request({
        url: 'bivale/energy/kanban/branch/delete/'+id,       
        method: 'post',
        data:null
    });
}

// 能源管理 -- 看板--天气信息
export function getEnergyKanBanWeather(id,level){
    return request({
        url: 'bivale/weather/now?id='+id+'&level='+level,       
        method: 'get',
        data:null
    });
}

// 能源管理 -- 看板--警报信息
export function getEnergyKanBanAlarmCount(id,level){
    return request({
        url: 'bivale/alarm/history/count/statistical?id='+id+'&level='+level,       
        method: 'post',
        data:null
    });
}


 // 能源管理 --  能效KPI显示--KPI警报设置相关API
 export function getKPIAutoTaskList(params){
    return request({
        url: 'bivale/energy/reduction/aim/task/list',
        method: 'post',
        data: params
    });
}

export function updateKPIAutoTask(params){
    return request({
        url: 'bivale/energy/reduction/aim/task/update',
        method: 'post',
        data: params
    });
}

export function batchUpdateKPIAutoTask(params){
    return request({
        url: 'bivale/energy/reduction/aim/task/batch/update',
        method: 'post',
        data: params
    });
}

export function downloadEnergyCSVDataWithURL(url,param){
    console.log("能源模块--下载CSV数据",param);
    return request({
        url: url,
        method: 'post',
        data:param
    });
} 

// 设备新增/修改
export function saveCommonEquipment(params) {
    return request({
        url: 'bivale/energy/device/common/equipment/save',       
        method: 'post',
        data:params
    });
}
// 设备信息
export function getCommonEquipment(params) {
    return request({
        url: 'bivale/energy/device/common/equipment/info',       
        method: 'post',
        data:params
    });
} 
// 设备列表
export function getCommonEquipments(params) {
    return request({
        url: 'bivale/energy/device/common/equipment/list',       
        method: 'post',
        data:params
    });
} 

// 设备列表
export function getDistributionEquipmentCount(params) {
    return request({
        url: 'bivale/energy/device/common/equipment/count',       
        method: 'post',
        data:params
    });
} 

// 设备删除
export function deleteCommonEquipments(params) {
    return request({
        url: 'bivale/energy/device/common/equipment/delete',       
        method: 'post',
        data:params
    });
} 


export function initBoardAccessKey(params) {
    return request({
        url: 'bivale/init/customization/key',       
        method: 'post',
        data:params
    });
} 

export function getUserSiteServiceList(params) {
    return request({
        url: 'bivale/custom/site/service/list',            
        method: 'post',
        data:params
    });
} 
